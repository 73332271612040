/**
 * This module appends the current page's search parameters to all <a> tags
 * on the page, preserving any existing search parameters in the links.
 */

/**
 * Appends current page's search parameters to all <a> tags.
 */
function portSearchParamsToLinks(): void {
  setTimeout(() => {
    // Get the current page's search parameters
    const currentSearchParams = new URLSearchParams(window.location.search);

    // Select all <a> elements with an href attribute
    const links = document.querySelectorAll<HTMLAnchorElement>("a[href]");

    links.forEach((link: HTMLAnchorElement) => {
      try {
        // Get the href attribute value
        const href = link.getAttribute("href");
        if (!href) return;
        if (
          href.startsWith("tel:") ||
          href.startsWith("mailto:") ||
          href.startsWith("sms:") ||
          href.startsWith("javascript:") ||
          href.startsWith("#")
        )
          return;

        // Create a URL object relative to the current origin
        const linkUrl = new URL(href, window.location.origin);

        // Check if the link is external by comparing origins

        // Get the link's existing search parameters
        const linkSearchParams = new URLSearchParams(linkUrl.search);

        // Iterate over the current page's search parameters
        currentSearchParams.forEach((value, key) => {
          // If the link doesn't already have this parameter, append it
          if (!linkSearchParams.has(key)) {
            linkSearchParams.append(key, value);
          }
        });

        // Reconstruct the href with updated search parameters and existing hash
        const updatedHref = `${linkUrl.origin}${linkUrl.pathname}${
          linkSearchParams.toString() ? `?${linkSearchParams.toString()}` : ""
        }${linkUrl.hash}`;

        // Update the link's href attribute
        link.setAttribute("href", updatedHref);
      } catch (error) {
        console.error(
          `Failed to process link: ${link.getAttribute("href")}`,
          error,
        );
      }
    });
  }, 700);
}

// Execute the function immediately since this is a module script
portSearchParamsToLinks();
